/**
 * Created by LiuYang on 2017/11/14.
 */
import {
  formatDate
} from './Fn.js';

/** Class representing a Single. */
class CDate {
  static __timeDiff = 0;
  __date = null;

  /**
   * 实例一个CDate
   * @param {String|TimeStamp|Number|Date} [args] 传入时间戳
   */
  constructor(...args) {
    let dateStr = args[0];
    if (dateStr === undefined) {
      dateStr = CDate.getServerDate();
    }
    if (dateStr instanceof CDate) {
      dateStr = dateStr.__date;
    }
    let __date = new Date(dateStr);
    __date = isNaN(__date.getTime()) ? new Date(dateStr.toString().replace(/-/g, '/')) : __date;
    if (isNaN(__date.getTime())) {
      throw new Error('invalide time');
    }
    this.__date = __date;
  }


  /**
   * 简单的日期格式化处理函数
   * @param {String} fmt 'yyyy-MM-dd hh:mm:ss'
   * @returns {String} 返回最终的对应的时间格式
   */
  format(fmt) {
    return formatDate(this.__date, fmt);
  }


  /**
   * 在原来的基础上进行日期天数的处理
   * @param {Number}  num 需要累加的时长
   * @param {String}  type 累加的单位，如`Year`,`Month`,`Day`,`Second`
   * @param {Boolean} [clone=false] 如果为true，则返回一个新的CDate实例
   * @returns {CDate}
   */
  add(num, type, clone = false) {
    let timeStamp = this.__date.getTime(),
      rate = 60,
      newDate = new Date(timeStamp),
      Year,
      month;
    switch (type) {
      case 'Year':
        Year = newDate.getFullYear();
        Year += num;
        newDate.setFullYear(Year);
        rate = newDate.getTime() - timeStamp;
        break;
      case 'Month':
        month = newDate.getMonth();
        month += num;
        newDate.setMonth(month);
        rate = newDate.getTime() - timeStamp;
        break;
      case 'Day':
        rate = 86400000 * num;
        break;
      case 'Second':
        rate = 1000 * num;
        break;
      default:
    }
    timeStamp += rate;
    if (clone === true) {
      return new CDate(timeStamp);
    }
    this.__date = new Date(timeStamp);
    return this;
  }

  /**
   * 在当前年份上添加一年
   * @param {Number} year  需要添加的年数
   * @param {Boolean} [clone=false]
   * @returns {CDate}
   */
  addYear(year, clone=false) {
    return this.add(year, 'Year', clone);
  }
  /**
   * 在当前时间上，累加一个月
   *
   * @param {Number}  month 需要添加的月数
   * @param {Boolean} [clone=false]
   * @returns {CDate}
   */
  addMonth(month, clone=false) {
    return this.add(month, 'Month', clone);
  }
  /**
   * 在当前天数上，累加一天
   * @param {Number} day 需要累加的天数
   * @param {Boolean} [ clone=false ] 是否需要返回一个新的Cdate实例
   * @returns {CDate}
   */
  addDay(day, clone = false) {
    return this.add(day, 'Day', clone);
  }

  /**
   * 获取当前时间戳
   * @returns {*|number}
   */
  getTime() {
    return this.__date.getTime();
  }

  /**
   * 获取JS原生的Date对像
   * @returns {Date}
   */
  getOriginDate(){
    return this.__date;
  }

  /**
   * 对当前日期进行格式化
   * @param {CDate|Date|String|Number} date 一个合法的时间表示格式
   * @param {String} fmt 需要格式化的样式
   * @returns {String}
   */
  static format(date, fmt) {
    return new CDate(date).format(fmt);
  }

  /**
   * 对比两个之间的差值
   * @param {CDate|Date|String|Number} dateNew 需要对比日期
   * @param {CDate|Date|String|Number} dateBase 需要对比的基准日期
   * @param {String} type 对比的类型
   * @returns {number} 对比之后的结果
   */
  static diff(dateNew, dateBase, type) {
    let nDate,
      bDate,
      nMonth,
      nYear,
      bMonth,
      bYear;
    switch (type) {
      case 'Year':
        nDate = CDate.format(dateNew, 'yyyy');
        bDate = CDate.format(dateBase, 'yyyy');
        return nDate - bDate;
      case 'Month':
        nDate = new CDate(dateNew);
        bDate = new CDate(dateBase);
        nYear = nDate.format('yyyy');
        nMonth = nDate.format('MM');
        bYear = bDate.format('yyyy');
        bMonth = bDate.format('MM');
        return (nYear - bYear) * 12 + nMonth - bMonth;
      case 'Day':
        nDate = new CDate(CDate.format(dateNew, 'yyyy/MM/dd') + ' 00:00:00');
        bDate = new CDate(CDate.format(dateBase, 'yyyy/MM/dd') + ' 00:00:00');
        return (nDate.getTime() - bDate.getTime()) / 86400000;
      case 'Hour':
        nDate = new CDate(CDate.format(dateNew, 'yyyy/MM/dd hh') + ':00:00');
        bDate = new CDate(CDate.format(dateBase, 'yyyy/MM/dd hh') + ':00:00');
        return (nDate.getTime() - bDate.getTime()) / 3600000;
      case 'Minutes':
        nDate = new CDate(CDate.format(dateNew, 'yyyy/MM/dd hh:mm') + ':00');
        bDate = new CDate(CDate.format(dateBase, 'yyyy/MM/dd hh:mm') + ':00');
        return (nDate.getTime() - bDate.getTime()) / 60000;
      default:
        nDate = new CDate(dateNew);
        bDate = new CDate(dateBase);
        return (nDate.getTime() - bDate.getTime()) / 1000;
    }
  }

  /**
   * 对比两个日期之前相差的天数
   * @param {CDate|Date|String|Number} dateNew
   * @param {CDate|Date|String|Number} dateBase
   * @returns {number}
   */
  static diffDay(dateNew, dateBase) {
    return CDate.diff(dateNew, dateBase, 'Day');
  }

  /**
   * 对比两个日期之间的年数
   * @param {CDate|Date|String|Number} dateNew
   * @param {CDate|Date|String|Number} dateBase
   * @returns {number}
   */
  static diffYear(dateNew, dateBase) {
    return CDate.diff(dateNew, dateBase, 'Year');
  }

  /**
   * 对比两个日期之间的月份数
   * @param {CDate|Date|String|Number} dateNew
   * @param {CDate|Date|String|Number} dateBase
   * @returns {number}
   */
  static diffMonth(dateNew, dateBase) {
    return CDate.diff(dateNew, dateBase, 'Month');
  }

  /**
   * 获取服务器端的时间对像
   * @returns {CDate}
   */
  static getServerDate() {
    return new CDate(new Date().getTime() + CDate.__timeDiff);
  }

  /**
   * 设置时间差
   */
  static setTimeDifference(serverDate){
    if(CDate.__timeDiff) return ;
    let now = new Date().getTime();
    let serverTimeStamps = new Date(serverDate).getTime();
    CDate.__timeDiff = serverTimeStamps - now;
  }
}

export default CDate;
