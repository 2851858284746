<template>
  <div class="confirm_box">
    <div class="confirm_con">
      <div class="con_pic con_pic_warning" v-if="showPic"></div>
      <p class="con_tip" v-if="typeof confirmObj.tipText === 'string'">{{confirmObj.tipText}}</p>
      <div v-if="Array.isArray(confirmObj.tipText)">
        <p class="con_txt" v-for="(item, index) in confirmObj.tipText" :key="`t_${index}`">{{item}}</p>
      </div>
      <p v-if="confirmObj.initText" style="font-size: 0.14rem !important; line-height: 0.2rem"
         class="mt2rem c-grey">{{confirmObj.initText}}</p>
      <div :class="confirmObj.initText ? 'btn_top_mar' : ''" class="con_foo">
        <span :style="{width: lang === 'en_US' ? '2.76rem' : '2rem'}" class="main_big_btn main_theme_color_btn_white" @click="confirmHide">{{confirmObj.backText}}</span>
        <span :style="{width: lang === 'en_US' ? '2.76rem' : '2rem'}" class="main_big_btn main_theme_color_btn ml20" @click="confirmSubmit">{{confirmObj.okText}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      confirmObj: {
        type: Object,
        default: () => ({})
      },
      showPic: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        lang: localStorage.getItem('user_lang') || 'zh_CN',
      }
    },
    methods: {
      confirmSubmit() {
        this.$emit('confirmSubmit');
      },
      confirmHide() {
        this.$emit('confirmHide');
      }
    }
  }
</script>

<style scoped lang="scss">
  .btn_top_mar{
    margin-top: 0.18rem !important;
  }
  .confirm_box{
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    overflow: hidden;
    z-index: 4000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    .confirm_con{
      width: 6rem;
      padding: 0.44rem 0.2rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      min-height: 2.88rem;
      background-color: #fff;
      border-radius: 0.1rem;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .con_pic{
        width: 0.54rem;
        height: 0.54rem;
        margin-bottom: 0.32rem;
        background-size: 100% 100%;
      }
      .con_pic_warning{
        background-image: url("/static/images/modal_warning.png");
      }
      .con_tip{
        max-width: 80%;
        color: #333;
        font-size: 0.2rem;
        text-align: center;
        line-height: 0.26rem;
      }
      .con_txt{
        width: 70%;
        margin: 0 auto;
        color: #666;
        font-size: 0.16rem;
        line-height: 0.24rem;
      }
      .con_foo{
        margin-top: 0.51rem;
        display: flex;
        align-items: center;
      }
    }
  }
</style>
