/**
 * @author LiuYang <liuyang@lyblog.net>
 * @fileOverview Enables a schema and validation feature set to your document or other object.
 * @module common/Fn
 * @require lodash
 */

import {
  isObject,
  isString,
  isEqual,
  isEmpty,
  isNumber,
  each,
  clone,
  map,
  extend
} from 'lodash';

/**
 * 在系统中直接生成一个唯一编码
 * @returns {string}
 * @constructor
 */
function GUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  }).toUpperCase();
}

/**
 * 生成随机码
 * @size 长度
 * @randomFlag 允许随机出现的字符数组
 */
function generateRandom(size, randomFlag) {
  let words = randomFlag || [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
    'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ];

  let str = '';
  let range = size || 6;
  for (let i = 0; i < range; i++) {
    let pos = Math.round(Math.random() * (words.length - 1));
    str += words[pos];
  }

  return str;
}

/**
 * 处理动态加载Js代码
 * @param {String} url 需要动态加载的js的地址
 * @returns {Promise}
 */
const lazyload = function (url, attrs) {
  let cache = (lazyload.cache = lazyload.cache || {});
  if (cache[url] === undefined) {
    cache[url] = {
      resolveArr: [],
      rejectArr: []
    };
  }
  cache = cache[url];
  let resolveArr = cache.resolveArr;
  let rejectArr = cache.rejectArr;

  if (cache['status'] === 'ok') {
    return new Promise(function (resolve) {
      window.setTimeout(function () {
        resolve(true);
      });
    });
  }
  let ret = null;
  let status = cache['status'];
  if (status === undefined || status === 'pending' || status === 'fail') {
    ret = new Promise(function (resolve, reject) {
      resolveArr.push(resolve);
      rejectArr.push(reject);
    });
  }

  if (status === undefined || status === 'fail') {
    let script = document.createElement('script');
    script.src = url;
    if (status === 'fail') {
      script.src += ((script.src.indexOf('?') === -1 ? '?' : '&') + 'v=' + Date.parse(new Date()));
    }
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('charset', 'utf-8');
    script.setAttribute('async', '');

    if (isObject(attrs) && !isEmpty(attrs)) {
      each(attrs, function (value, key) {
        script.setAttribute(key, value);
      });
    }

    script.onload = function () {
      cache['status'] = 'ok';
      lazyload.rejectArr = [];
      let resolve = null;
      // eslint-disable-next-line no-cond-assign
      while (resolve = resolveArr.shift()) {
        resolve(true);
      }
    };

    script.onerror = function () {
      cache['status'] = 'fail';
      let reject = null;
      lazyload.resolveArr = [];
      // eslint-disable-next-line no-cond-assign
      while (reject = rejectArr.shift()) {
        reject(false);
      }
    };
    cache['status'] = 'pending';
    document.head.appendChild(script);
  }

  return ret;
};

/**
 * 处理日期格式化
 * @param d
 * @param mask
 */
const formatDate = function (d, mask) {
  var zeroize = function (value, length) {
    if (!length) length = 2;
    value = String(value);
    for (var i = 0, zeros = ''; i < (length - value.length); i++) {
      zeros += '0';
    }
    return zeros + value;
  };
  return mask.replace(/"[^"]*"|'[^']*'|\b(?:d{1,4}|m{1,4}|yy(?:yy)?|([hHMstT])\1?|[lLZ])\b/g, function ($0) {
    switch ($0) {
      case 'd':
        return d.getDate();
      case 'dd':
        return zeroize(d.getDate());
      case 'ddd':
        return ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'][d.getDay()];
      case 'dddd':
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][d.getDay()];
      case 'M':
        return d.getMonth() + 1;
      case 'MM':
        return zeroize(d.getMonth() + 1);
      case 'MMM':
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()];
      case 'MMMM':
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][d.getMonth()];
      case 'yy':
        return String(d.getFullYear()).substr(2);
      case 'yyyy':
        return d.getFullYear();
      case 'h':
        return d.getHours() % 12 || 12;
      case 'hh':
        return zeroize(d.getHours() % 12 || 12);
      case 'H':
        return d.getHours();
      case 'HH':
        return zeroize(d.getHours());
      case 'm':
        return d.getMinutes();
      case 'mm':
        return zeroize(d.getMinutes());
      case 's':
        return d.getSeconds();
      case 'ss':
        return zeroize(d.getSeconds());
      case 'l':
        return zeroize(d.getMilliseconds(), 3);
      case 'L':
        var m = d.getMilliseconds();
        if (m > 99) m = Math.round(m / 10);
        return zeroize(m);
      case 'tt':
        return d.getHours() < 12 ? 'am' : 'pm';
      case 'TT':
        return d.getHours() < 12 ? 'AM' : 'PM';
      case 'Z':
        return d.toUTCString().match(/[A-Z]+$/);
      // Return quoted strings with the surrounding quotes removed

      default:
        return $0.substr(1, $0.length - 2);
    }

  });
};

// 来对比当前两个对象是否相等
const isExceptEqual = function (nVal, oVal, except) {
  let nValue = nVal ? clone(nVal) : nVal;
  let oValue = oVal ? clone(oVal) : oVal;
  each(except, function (item) {
    try {
      delete nValue[item];
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
    try {
      delete oValue[item];
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  });
  let ret = isEqual(nValue, oValue);
  return ret;
};

// 处理数据中的值唯一
const uniqueArray = function uniqueArray(array) {
  var res = [];
  var json = {};
  for (var i = 0; i < array.length; i++) {
    if (!json[array[i]]) {
      res.push(array[i]);
      json[array[i]] = 1;
    }
  }
  return res;
};

// cdn地址转换
const cdnFn = function (value, width, height) {
  if (!isString(value)) {
    // eslint-disable-next-line no-console
    console.warn('value 值不是字符串');
    value = '';
  }
  let cdnConfig = config.cdn;
  let cdnPrefix = config.cdnPrefix;
  let cdnRename = config.cdnRename;

  let isCdn = false;
  each(cdnConfig, function (val, item) {
    if (value.indexOf(item) !== -1) {
      isCdn = true;
      if (val) {
        value = value.replace(item, val);
      }
      return false;
    }
  });

  if (!isCdn || (isCdn && ((cdnPrefix && value.indexOf(cdnPrefix) !== -1)) || !cdnPrefix)) {
    return value;
  }
  let afterFix = '';
  if (isNumber(width)) {
    height = isNumber(height) ? height : width;
    afterFix = `/quality/80/compress/true/progressive/true/strip/false/exifswitch/false/rotate/auto/both/${width}x${height}`;
  }
  if (isString(width)) {
    if ((width in cdnRename)) {
      afterFix = cdnPrefix;
    } else {
      afterFix = width;
    }
  }

  return afterFix ? (value + cdnPrefix + afterFix) : value;
};


function removeClass(all, rmv) {
  rmv = " " + (rmv || '').split(/\s+/).join(' | ') + " ";
  return (" " + all + " ")
    .replace(/\s+/gi, '  ')
    .replace(new RegExp(rmv, 'gi'), ' ')
    .replace(/\s+/gi, ' ')
    .replace(/^\s+|\s+$/gi, '');
}

function addClass(all, add) {
  all = removeClass(all, add);
  return (all + " " + add)
    .replace(/\s+/gi, ' ')
    .replace(/^\s+|\s+$/gi, '');
}

function matchesSelector(element, selector) {
  if (element.matches) {
    return element.matches(selector);
  } else if (element.matchesSelector) {
    return element.matchesSelector(selector);
  } else if (element.webkitMatchesSelector) {
    return element.webkitMatchesSelector(selector);
  } else if (element.msMatchesSelector) {
    return element.msMatchesSelector(selector);
  } else if (element.mozMatchesSelector) {
    return element.mozMatchesSelector(selector);
  } else if (element.oMatchesSelector) {
    return element.oMatchesSelector(selector);
  } else if (element.querySelectorAll) {
    var matches = (element.document || element.ownerDocument).querySelectorAll(selector),
      i = 0;

    while (matches[i] && matches[i] !== element) i++;
    return matches[i] ? true : false;
  }
  throw new Error('Your browser version is too old,please upgrade your browser');
}

// 获取随机背景色
function randomBgColor(id) {
  var num = 0;
  if (id) {
    num = Math.floor(id % 5);
  } else {
    num = Math.floor(Math.random() * 5)
  }
  return {
    0: '#4da9eb',
    1: '#f2725e',
    2: '#16c295',
    3: '#f8b45e',
    4: '#548aac'
  }[num];
}

/**
 * 把字母转换成骆峰式命名
 *
 * @param {string} keys
 * @return {string}
 */
function studlyCase(keys) {
  return keys.replace(/_(\S)|(^\S)/gi, function (all, rep1, rep2) {
    return String(rep1 || rep2).toUpperCase();
  });
}

/**
 * 通过Js来解析链接地址
 * @param url
 * @return {{href: *, protocol, host, hostname: (string|*|(() => string)), path: string, port: (*|string), pathname: string, hash, search, query: *}}
 */
function parseUrl(url) {
  var anchor = document.createElement('a');
  anchor.href = url;
  var path = anchor.pathname,
    search = anchor.search,
    query = null;
  if (search) {
    var keyValue = search.replace(/^\?/, '').split('&'),
      tempValue;
    query = {};
    for (var i = 0, j = keyValue.length; i < j; i++) {
      tempValue = keyValue[i].split('=');
      query[tempValue[0]] = tempValue[1];
    }
  }
  var defaultPort = {
    "http:": 80,
    "https:": 443,
    "ftp:": 21,
    "gopher:": 70,
    "ws:": 80,
    "wws": 443
  };
  return {
    href: anchor.href,                      //full url
    protocol: anchor.protocol,              //protocol,            eg: http:, https:, ftp:
    host: anchor.host,                      //host,                eg: www.lyblog.net:8087
    hostname: anchor.hostname,              //hostname,            eg: www.lyblog.net
    path: path + search,                    //path and search,     eg: www.lyblog.net/archive/2015/566.html?debug=1
    port: anchor.port || defaultPort[anchor.protocol] || "",
    //port,                eg: 8087
    pathname: path,                         //path,                eg: /archive/2015/566.html
    hash: anchor.hash,                      //hash,                eg: #respond
    search: search,                         //search,              eg: ?search=1&debug=2
    query: query                            //query                eg: JSON
  };
}

/**
 * 把某个对象转换成唯一字符串
 * @param object
 * @param sortWith
 * @return {string}
 */
function sortObjectByKeyNameList(object, sortWith) {
  var keys;
  var sortFn;

  if (typeof sortWith === 'function') {
    sortFn = sortWith;
  } else {
    keys = sortWith;
  }
  return (keys || []).concat(Object.keys(object).sort(sortFn)).reduce(function (total, key) {
    total[key] = object[key];
    return total;
  }, Object.create(null));
}

/**
 * 快速生成二维码
 * @param {string} url 需要编码的内容
 * @param {number} size 需要的尺寸
 * @return {string}
 */
function qrCode(url, size = 300) {
  let params = {
    w: size,
    h: size,
    url: url
  };
  return 'https://pan.baidu.com/share/qrcode?' + map(params, function (item, key) {
    return key + '=' + encodeURIComponent(item);
  }).join('&');
}

/**
 *
 * @param str
 * @param def
 * @returns {number}
 */
function toNumber(str, def) {
  let num = parseInt(str);
  if (isNaN(num)) {
    return def === undefined ? 0 : def;
  }
  return num;
}


/**
 * 数组转树开结构
 * @param list array
 * @param parentId number|null
 * @param key string
 * @returns {Array}
 */
function toTreeFn(list, parentId = null) {
  let result = [];
  let current;
  for (let i = 0, j = list.length; i < j; i++) {
    current = list[i];
    if (current.resources) {
      current.children = map(current.resources, (item) => {
        return extend({}, item, {
          title: item.name + '.' + item.file_type
        })
      });
    }
    if (current.parent_id === parentId) {
      let children = toTreeFn(list, current.id);
      let res;
      if (children.length) {
        res = extend({
          children: children
        }, current)
      } else {
        res = extend({}, current)
      }
      result.push(res)
    }
  }
  return result
}
function chapterSort(before,after,resources,resourceIds,idType,params,changeFunctions) {
  let {changeFunction,getList} = changeFunctions;
  let judgeLocation = resourceIds.indexOf(before.id) < resourceIds.indexOf(after.id)?before:after;
  let headList = resources.slice(0,resourceIds.indexOf(judgeLocation.id));
  let footList = resources.slice(headList.length,resources.length);
  let clearFootList = map(footList,item=>{
    if(before.id === item.id){
      return {
        id : item.id,
        sort: after.sort + 1,
      }
    }else if(after.id === item.id){
      return {
        id : item.id,
        sort: after.sort,
      }
    }else{
      if(item.sort < after.sort){
        return {
          id : item.id,
          sort: item.sort,
        }
      }else{
        return {
          id : item.id,
          sort: item.sort + 5,
        }
      }
    }
  });
  let list = map([...headList,...clearFootList],item=>{
    return {id:item.id,sort:item.sort,}
  });
  let obj = {};
  obj[idType] = params;
  obj['list'] = list;
 return changeFunction(obj).then(() => {
    getList();
  }).catch(() => {
    getList();
  });
}
function checkFileMimeType(file, mimetypes) {
  return import('mime-types').then(function(mimetype) {
    let fileType = file.type || mimetype.default.lookup(file.name);
    return mimetypes.indexOf(fileType) !== -1;
  });
}

function changeRouteQuery(that,args) {
  let route = that.$route;
  that.$router.replace({
    name: route.name,
    query: extend({}, route.query,{pageNum: 1}, args)
  });
}

export {
  GUID,
  generateRandom,
  lazyload,
  formatDate,
  isExceptEqual,
  uniqueArray,
  removeClass,
  addClass,
  matchesSelector,
  randomBgColor,
  qrCode,
  sortObjectByKeyNameList,
  parseUrl,
  studlyCase,
  cdnFn,
  toNumber,
  toTreeFn,
  checkFileMimeType,
  chapterSort,
  changeRouteQuery
};
