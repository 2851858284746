<template>
  <div class="to_scan">
    <back-step
      :goList="[
        {
          url: '/cases',
          name: $t('casesDetail.casesChildren.common.bl'),
        },
        {
          url: `/cases/detail?caseId=${$route.query.caseId}&curePlanId=${$route.query.curePlanId}`,
          name: $t('casesDetail.casesChildren.common.blxq'),
        },
        {
          url: `/cases/casesDoKeep?caseId=${$route.query.caseId}&curePlanId=${$route.query.curePlanId}`,
          name: $t('casesDetail.casesChildren.casesToScan.zbc'),
        },
      ]"
      :currentTxt="$t('casesDetail.casesChildren.casesToScan.cxsm')"
    />
    <div class="create_image">
      <div class="image_single">
        <div class="single_tit">
          <p class="s_tit_l">
            <span class="vertical_line"></span>
            {{ $t("casesDetail.casesChildren.casesToScan.yhmx") }}
            <span class="tit_icon"></span>
            <span class="tit_tip">{{
              $t("casesDetail.casesChildren.casesToScan.mxbz")
            }}</span>
          </p>
        </div>
        <div class="single_con">
          <div class="s_c_box">
            <div class="s_c__left">
              {{ $t("casesDetail.casesChildren.casesToScan.gxmx") }}
            </div>
            <div class="s_c__right">
              <div
                class="s_c__r_box"
                @click.stop="
                  (ev) => {
                    selectedProblem(ev, 'siliconeRubberModel');
                  }
                "
              >
                <template
                  v-for="(item, index) in dictObj['silicone_rubber_model']"
                >
                  <span
                    :class="
                      diagnosisParams.siliconeRubberModel === item.key
                        ? 's_c__sp_active'
                        : ''
                    "
                    class="s_c__sp"
                    v-if="isShowModel(item.key)"
                    :key="`${item.key}_${index}0`"
                    :data-key="item.key"
                    >{{ item.value }}</span
                  >
                </template>
                <p style="margin-left: 0.2rem; color: #ff6a08">
                  {{ $t("common.common.shxh") }}
                </p>
              </div>
              <p
                class="s_c__p"
                v-show="diagnosisParams.siliconeRubberModel === 'silicagel_print_model'"
              >
                {{ $t("casesDetail.casesChildren.casesToScan.blxh") }}
              </p>
              <div
                class="s_c__tip"
                v-show="diagnosisParams.siliconeRubberModel === 'silicagel_print_model'"
              >
                <p class="s_c_fkd" @click="sendExpress">
                  <span class="fkd_icon"></span>
                  {{ $t("casesDetail.casesChildren.casesToScan.fkd") }}
                </p>
                <p class="s_c__tip_p">
                  <span class="fz14">{{
                    $t("casesDetail.casesChildren.casesToScan.blxh01")
                  }}</span>
                  <input
                    readonly
                    id="casesNumber"
                    class="tip_sp_strong fz14"
                    :value="get(diagnosisParams, 'caseInfo.caseNumber')"
                  />
                  <span
                    ref="copyCases"
                    class="tip_sp_icon"
                    data-clipboard-action="copy"
                    data-clipboard-target="#casesNumber"
                    @click="copyCasesNumber"
                  ></span>
                </p>
              </div>
            </div>
          </div>
          <div class="s_c_box">
            <div class="s_c__left">
              {{ $t("casesDetail.casesChildren.casesToScan.szmx") }}
            </div>
            <div class="s_c__right">
              <div
                class="s_c__r_box"
                @click.stop="
                  (ev) => {
                    selectedProblem(ev, 'digitalModelFile');
                  }
                "
              >
                <span
                  :class="
                    diagnosisParams.digitalModelFile === item.key
                      ? 's_c__sp_active'
                      : ''
                  "
                  class="s_c__sp"
                  v-for="(item, index) in dictObj['digital_model_file']"
                  :key="`${item.key}_${index}1`"
                  :data-key="item.key"
                  >{{ item.value }}</span
                >
              </div>
              <div v-show="diagnosisParams.digitalModelFile === 'local_upload'">
                <div class="s_c__up">
                  {{ $t("cases.createImage.se") }}
                  <el-upload
                    class="upload-file"
                    drag
                    :data="{ token: uploadToken }"
                    :before-upload="
                      (file) =>
                        beforeFileUpload(file, 'sharp_upjaw_remain', 'stl')
                    "
                    :action="UPLOAD_URL"
                    ref="sharpUpJaw"
                  >
                    <span class="up_load">{{
                      $t("cases.createImage.scstl")
                    }}</span>
                  </el-upload>
                  <span
                    class="up_icon"
                    @click="clickSharpUpJaw('sharpUpJaw')"
                  ></span>
                  <p class="up_file" v-show="fileNameObj['sharp_upjaw_remain']">
                    {{ fileNameObj["sharp_upjaw_remain"] }}
                    <span class="up_status el-icon-success"></span>
                  </p>
                </div>
                <div class="s_c__up">
                  {{ $t("cases.createImage.xe") }}
                  <el-upload
                    class="upload-file"
                    drag
                    :data="{ token: uploadToken }"
                    :before-upload="
                      (file) =>
                        beforeFileUpload(file, 'sharp_downjaw_remain', 'stl')
                    "
                    :action="UPLOAD_URL"
                    ref="sharpDownJaw"
                  >
                    <span class="up_load">{{
                      $t("cases.createImage.scstl")
                    }}</span>
                  </el-upload>
                  <span
                    class="up_icon"
                    @click="clickSharpUpJaw('sharpDownJaw')"
                  ></span>
                  <p
                    class="up_file"
                    v-show="fileNameObj['sharp_downjaw_remain']"
                  >
                    {{ fileNameObj["sharp_downjaw_remain"] }}
                    <span class="up_status el-icon-success"></span>
                  </p>
                </div>
              </div>
              <div v-show="diagnosisParams.digitalModelFile === 'iTero'">
                <div class="s_c__up">
                  {{ $t("cases.createImage.wj") }}
                  <el-upload
                    class="upload-file"
                    drag
                    :data="{ token: uploadToken }"
                    :before-upload="
                      (file) => beforeFileUpload(file, 'iTero', 'zip')
                    "
                    :action="UPLOAD_URL"
                    ref="iTero"
                  >
                    <span class="up_load">{{
                      $t("cases.createImage.sczip")
                    }}</span>
                  </el-upload>
                  <span
                    class="up_icon"
                    @click="clickSharpUpJaw('iTero')"
                  ></span>
                  <p class="up_file" v-show="fileNameObj['iTero']">
                    {{ fileNameObj["iTero"] }}
                    <span class="up_status el-icon-success"></span>
                  </p>
                </div>
              </div>
              <div v-show="diagnosisParams.digitalModelFile === 'other'">
                <div class="s_c__up">
                  {{ $t("cases.createImage.wj") }}
                  <el-upload
                    class="upload-file"
                    drag
                    :data="{ token: uploadToken }"
                    :before-upload="
                      (file) =>
                        beforeFileUpload(
                          file,
                          'other_file_remain',
                          'zip',
                          'other'
                        )
                    "
                    :action="UPLOAD_URL"
                    ref="otherFile"
                  >
                    <span class="up_load">{{
                      $t("cases.createImage.sczip")
                    }}</span>
                  </el-upload>
                  <span
                    class="up_icon"
                    @click="clickSharpUpJaw('otherFile')"
                  ></span>
                  <ul
                    v-show="
                      fileNameObj['other_file_remain'] &&
                      fileNameObj['other_file_remain'].length
                    "
                  >
                    <li
                      class="up_file other_file"
                      v-for="(item, index) in fileNameObj['other_file_remain']"
                      :key="index"
                    >
                      {{ item.name }}
                      <span class="up_status el-icon-success"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                v-if="historyFileList.length"
                @click="lookHistoryFile"
                class="history_submit_btn"
              >
                {{ $t("cases.createImage.lstj") }}
              </div>
            </div>
          </div>
        </div>
        <!--<the-delivery-->
        <!--v-if="isSendExpress && commonCaseDetail.state === 'loadend'"-->
        <!--@subScheduling="subScheduling"-->
        <!--@removeScheduling="removeScheduling"-->
        <!--:caseDetail="commonCaseDetail.detail"-->
        <!--:ref-type="'3'"-->
        <!--/>-->
        <confirm-modal
          :confirmObj="{
            tipText: $t('casesDetail.casesChildren.common.qrjx'),
            backText: $t('casesDetail.casesChildren.common.qx'),
            okText: $t('casesDetail.casesChildren.common.qd'),
          }"
          @confirmSubmit="confirmSubmit"
          @confirmHide="confirmHide"
          v-show="isShow"
        />
        <full-loading v-show="isLoading" />
      </div>
      <div class="c_foo">
        <p :style="{width: '2rem'}" class="main_big_btn main_theme_color_btn" @click="isShow = true">
          {{ $t("casesDetail.casesChildren.common.qd") }}
        </p>
      </div>
    </div>
    <el-dialog
      :title="$t('cases.createImage.lstj')"
      :visible.sync="historyFileVisible"
    >
      <div style="height: 4rem">
        <el-scrollbar style="height: 100%">
          <el-table :data="historyFileList">
            <el-table-column
              prop="createDatetime"
              :label="$t('home.home.sj')"
              width="200"
            ></el-table-column>
            <el-table-column
              prop="node"
              :label="$t('casesDetail.casesDetail.jd')"
              width="150"
            >
              <template slot-scope="scope">
                <div>
                  {{ stageDictObj[scope.row.stage] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="450"
              :label="$t('casesDetail.casesDetail.wjm')"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    map(
                      scope.row.attachmentHisList,
                      (item) => item.realFileName
                    ).join(",")
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              :label="$t('common.common.xz')"
              width="100"
            >
              <template slot-scope="scope">
                <el-button
                  @click="downFile(scope.row)"
                  type="text"
                  size="small"
                  >{{ $t("common.common.xz") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="historyFileVisible = false">{{
          $t("casesDetail.casesChildren.common.qx")
        }}</el-button>
        <el-button type="primary" @click="historyFileVisible = false">{{
          $t("casesDetail.casesChildren.common.qd")
        }}</el-button>
      </span>
    </el-dialog>
    <ExBirdie
      :defaultKey="exDefaultKey"
      :refType="'3'"
      :caseDetail="commonCaseDetail.detail"
      ref="exBirdie"
      @tabsChange="tabsChanged"
    />
  </div>
</template>

<script>
import $ from "jquery";
import * as QINIU from "qiniu-js";
import clipboard from "clipboard";
import { getDictAllList, getQiniuToken, getDictList } from "common/api/public";
import {
  detailCurePlan,
  curePlanRescan,
  PublicAttachmentLogList,
} from "common/api/cases";
import BackStep from "../childrenPublic/backStep";
import FullLoading from "components/full-loading/full-loading";
import TheDelivery from "../../cases-create/the-delivery";
import ConfirmModal from "components/confirm-modal/confirm";
import { notifyMsg, downloadFileList } from "common/js/util";
import { UPLOAD_URL } from "common/js/config";
import { extend, each, map, filter, isArray, get } from "lodash";
import { mapGetters, mapActions } from "vuex";
import CDate from "common/js/CDate";
import ExBirdie from "../../../../components/expressage_birdie/index";

export default {
  data() {
    return {
      isLoading: false,
      isShow: false,
      isSendExpress: false,
      copyCases: null,
      dictObj: {},
      diaKeyObj: {},
      defFileObj: {},
      diagnosisParams: {},
      UPLOAD_URL,
      uploadToken: "",
      fileNameObj: {
        other_file_remain: [],
        iTero_remain: "",
        sharp_upjaw_remain: "",
        sharp_downjaw_remain: "",
      },
      historyFileList: [],
      historyFileVisible: false,
      isEditFileList: [],
      stageDictObj: {},
      exDefaultKey: "",
    };
  },
  computed: {
    ...mapGetters({
      commonCaseDetail: "getCommonCaseDetail", //病例详情
    }),
  },
  methods: {
    ...mapActions({
      getCommonCaseDetail: "actionGetCommonCaseDetail", //病例详情
    }),
    map,
    get,
    isShowModel(key) {
      if (key === "silicagel_print_model") {
        return true;
      } else {
        return this.commonCaseDetail.detail.selfDeliverFlag == 1;
      }
    },
    tabsChanged(key) {
      this.diagnosisParams.siliconeRubberModel = key;
    },
    getDictList() {
      getDictList("case.stage").then((data) => {
        each(data, (item) => {
          this.stageDictObj[item.key] = item.value;
        });
      });
    },
    lookHistoryFile() {
      this.historyFileVisible = true;
    },
    downFile(item) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let name = `${this.diagnosisParams.userInfo.caseNumber}_${
        this.diagnosisParams.userInfo.realName
      }_${new CDate().format("yyyy-MM-dd")}`;
      downloadFileList(item.attachmentHisList, true, name, this.$PicPrefix);
      loading.close();
    },
    getHistoryFileList() {
      return PublicAttachmentLogList({
        caseId: this.$route.query.caseId,
        nodeList: ["to_remain"],
      }).then((data) => {
        this.historyFileList = data;
      });
    },
    confirmSubmit(noJump) {
      const id = this.$route.query.curePlanId;
      if (id) {
        let keys = {
          other: "other_file_remain",
          iTero: "iTero_remain",
          local_upload: ["sharp_upjaw_remain", "sharp_downjaw_remain"],
        }[this.diagnosisParams.digitalModelFile];
        if (
          this.isEditFileList.length !==
          this.diagnosisParams.attachmentInfoList.length
        ) {
          this.diagnosisParams.attachmentInfoList = map(
            this.diagnosisParams.attachmentInfoList,
            (item) => {
              return extend({}, item, { fileSource: "DOCTOR" });
            }
          );
        }
        if (
          this.isEditFileList.length ===
          this.diagnosisParams.attachmentInfoList.length
        ) {
          let list = [];
          each(this.isEditFileList, (old) => {
            each(this.diagnosisParams.attachmentInfoList, (newFile) => {
              if (old.fileName === newFile.fileName) {
                list.push(true);
              }
            });
          });
          if (list.length < this.isEditFileList.length) {
            this.diagnosisParams.attachmentInfoList = map(
              this.diagnosisParams.attachmentInfoList,
              (item) => {
                return extend({}, item, { fileSource: "DOCTOR" });
              }
            );
          }
        }
        const { siliconeRubberModel, digitalModelFile, attachmentInfoList } =
          this.diagnosisParams;
        this.isLoading = true;
        curePlanRescan({
          id,
          siliconeRubberModel,
          digitalModelFile,
          attachmentInfoList: filter(attachmentInfoList, (item) =>
            isArray(keys) ? keys.includes(item.fileKey) : item.fileKey === keys
          ),
        })
          .then(() => {
            this.isShow = false;
            this.isLoading = false;
            notifyMsg(
              this,
              "success",
              this.$t("casesDetail.casesChildren.common.czcg")
            );
            if (!noJump) {
              this.$router.replace({
                name: "CasesDoKeep",
                query: extend({}, this.$route.query),
              });
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.isShow = false;
          });
      } else {
        notifyMsg(
          this,
          "error",
          this.$t("casesDetail.casesChildren.common.czsb")
        );
      }
    },
    confirmHide() {
      this.isShow = false;
    },
    sendExpress() {
      this.exDefaultKey = "silicagel_print_model";
      this.$refs["exBirdie"].openDia().then(() => {
        this.$refs["exBirdie"] &&
          this.$refs["exBirdie"].resetClinic(
            this.diagnosisParams.caseInfo.clinicId
          );
        this.confirmSubmit("noJump");
      });
    },
    copyCasesNumber() {
      notifyMsg(this, "success", this.$t("cases.createImage.fzcg"));
    },
    beforeFileUpload(file, key, type) {
      const { name } = file;
      const _this = this;
      if (name.indexOf(type) > 0) {
        this.isLoading = true;
        const { attachmentInfoList } = _this.diagnosisParams;
        const list =
          key !== "other_file_remain"
            ? attachmentInfoList.filter((item) => item.fileKey !== key)
            : attachmentInfoList;
        const aBlob = new Blob([file]);
        const ts = new Date().getTime();
        const observable = QINIU.upload(
          aBlob,
          ts + "_" + name,
          this.uploadToken
        );
        observable.subscribe({
          error(err) {
            _this.isLoading = false;
          },
          complete(res) {
            const { hash } = res;
            _this.isLoading = false;
            _this.diagnosisParams.attachmentInfoList = [
              ...list,
              {
                fileKey: key === "iTero" ? "iTero_remain" : key,
                fileName: ts + "_" + name,
                realFileName: name,
                fileType: type,
                fileUrl: hash,
                fileSource: "DOCTOR",
              },
            ];
            _this.fileNameObj[key] =
              key !== "other_file_remain"
                ? ts + "_" + name
                : [..._this.fileNameObj[key], { name: ts + "_" + name }];
            notifyMsg(_this, "success", _this.$t("common.message.wjsccg"));
          },
        });
      } else {
        notifyMsg(
          this,
          "warning",
          `${this.$t("common.message.qxz")}${type}${this.$t(
            "common.message.wjsc"
          )}`
        );
      }
      return false;
    },
    beforeZipFileUpload(file) {
      if (file.type.indexOf("ZIP") > 0 || file.type.indexOf("zip") > 0) {
        this.isLoading = false;
        return true;
      } else {
        this.isLoading = false;
        notifyMsg(
          this,
          "warning",
          this.$t("casesDetail.casesChildren.casesToScan.xzzip")
        );
        return false;
      }
    },
    clickSharpUpJaw(ref) {
      this.$refs[ref].$el.childNodes[0] &&
        this.$refs[ref].$el.childNodes[0].click();
    },
    selectedProblem(ev, filed) {
      if (this.modeNoProblem) return;
      const target = ev.target;
      if (target.tagName.toLocaleLowerCase() === "span") {
        const val = target.getAttribute("data-key");
        if (filed === "siliconeRubberModel") {
          this.diagnosisParams.digitalModelFile = "";
          this.fileNameObj = {
            other_file: [],
            iTero: "",
            sharp_upjaw: "",
            sharp_downjaw: "",
          };
          if (val === "self_deliver") {
            this.$refs["exBirdie"].openDia().then(() => {
              this.$refs["exBirdie"] &&
                this.$refs["exBirdie"].resetClinic(this.caseDetail.clinicId);
              this.saveData();
            });
          }
          this.exDefaultKey = val;
        }
        if (filed === "digitalModelFile") {
          this.diagnosisParams.siliconeRubberModel = "";
        }
        if (this.diagnosisParams[filed] !== val) {
          this.remarkParams = {
            ...this.remarkParams,
            [filed]: null,
          };
          this.remarkObj = {
            ...this.remarkObj,
            [filed]: null,
          };
          this.diagnosisParams = {
            ...this.diagnosisParams,
            [filed]: val,
          };
        } else {
          if (filed !== "siliconeRubberModel") {
            delete this.diagnosisParams[filed];
            this.diagnosisParams = {
              ...this.diagnosisParams,
            };
          }
        }
        if (filed === "digitalModelFile") {
          window.scrollTo(0, 0);
        }
      }
    },
  },
  created() {
    document.title = this.$t("casesDetail.casesChildren.casesToScan.cxsm");
    this.diagnosisParams.caseId = this.$route.query.caseId;
    this.getCommonCaseDetail(this.diagnosisParams.caseId);
    this.getHistoryFileList();
    this.getDictList();
    let id = this.$route.query.curePlanId;
    if (id) {
      this.diagnosisParams.id = id;
      this.isLoading = true;
      detailCurePlan(id)
        .then((obj) => {
          const { attachmentList } = obj;
          this.isLoading = false;
          let subList = filter(attachmentList, (item) =>
            [
              "sharp_upjaw_remain",
              "sharp_downjaw_remain",
              "iTero",
              "other_file_remain",
            ].includes(item.fileKey)
          );
          this.isEditFileList = subList;
          this.diagnosisParams = extend({}, obj, {
            attachmentInfoList: subList,
          });
          const fileObj = {};
          attachmentList.forEach((item) => {
            const obj = {
              name: item.fileName,
              url: `${this.$PicPrefix}${item.fileUrl}`,
            };
            if (item.fileKey !== "other_file_remain") {
              this.fileNameObj[item.fileKey] = item.fileName;
            } else {
              this.fileNameObj["other_file_remain"].push({
                name: item.fileName,
              });
            }
            if (!fileObj[item.fileKey]) {
              fileObj[item.fileKey] = [obj];
            } else {
              fileObj[item.fileKey].push(obj);
            }
          });
          this.defFileObj = fileObj;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
    getQiniuToken().then((data) => {
      this.uploadToken = data;
    });
    getDictAllList(["silicone_rubber_model", "digital_model_file"]).then(
      (data) => {
        let dictObj = {};
        const diaKeyObj = {};
        data.forEach((item) => {
          if (!dictObj[item.parentKey]) {
            dictObj[item.parentKey] = [item];
          } else {
            dictObj[item.parentKey].push(item);
          }
          diaKeyObj[item.key] = item.value;
        });
        this.diaKeyObj = diaKeyObj;
        this.dictObj = dictObj;
      }
    );
  },
  mounted() {
    if (this.$refs.copyCases) {
      this.copyCases = new clipboard(this.$refs.copyCases);
    }
  },
  components: {
    BackStep,
    TheDelivery,
    ConfirmModal,
    FullLoading,
    ExBirdie,
  },
};
</script>

<style scoped lang="scss">
.to_scan {
  width: 13.44rem;
  margin: 0 auto;
  padding-top: 50px;
  .create_image {
    background-color: #fff;
    border-radius: 0.08rem;
    padding: 0.16rem 0.3rem;
    .image_single {
      margin-bottom: 0.5rem;
      .single_tit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.18rem;
        border-bottom: 0.01rem solid #d2d2d2;
        margin-bottom: 0.3rem;
        font-size: 18px;
        .s_tit_l {
          display: flex;
          align-items: center;
        }
        .s_tit_r {
          color: $main_theme_color;
          font-size: 0.18rem;
          cursor: pointer;
        }
        .tit_icon {
          display: inline-block;
          width: 0.16rem;
          height: 0.16rem;
          border-radius: 100%;
          background-image: url("/static/images/createCases/question.png");
          background-size: 100% 100%;
          margin-right: 0.06rem;
          margin-left: 0.16rem;
        }
        .tit_tip {
          color: $main_theme_color;
          margin-top: 0.01rem;
          font-size: 0.14rem;
        }
      }
      .single_con {
        .problem_note {
          color: $main_theme_color;
          margin-bottom: 0.2rem;
          line-height: 0.22rem;
          font-size: 0.16rem;
        }
        .s_c_box {
          display: flex;
          margin-bottom: 0.2rem;
          &:last-child {
            margin-bottom: 0;
          }
          .s_c__left {
            font-size: 16px;
            color: $main_theme_color_333;
            margin-top: 0.12rem;
            min-width: 0.96rem;
          }
          .s_c__right {
            position: relative;
            .history_submit_btn {
              position: absolute;
              top: 0.1rem;
              right: -1rem;
              color: #409eff;
              cursor: pointer;
            }
            .s_c__p {
              font-size: 0.14rem;
              color: $main_theme_color_666;
              margin-bottom: 0.1rem;
              margin-left: 18px;
            }
            .s_c__tip {
              width: 436px;
              height: 68px;
              padding: 0.16rem 0.2rem;
              background-color: #f3f3f3;
              border-radius: 0.02rem;
              position: relative;
              display: flex;
              align-items: center;
              margin-left: 18px;
              .tip_sp_strong {
                margin-left: 0.06rem;
                margin-right: 0.4rem;
                width: 80px;
              }
              .tip_sp_icon {
                display: inline-block;
                width: 0.18rem;
                height: 0.18rem;
                background-image: url("/static/images/createCases/copy_num.png");
                background-size: 100% 100%;
                cursor: pointer;
              }
              .s_c_fkd {
                position: absolute;
                top: 0.16rem;
                right: 0.2rem;
                border: 0.01rem solid $main_theme_color;
                border-radius: 0.06rem;
                min-width: 1.06rem;
                height: 0.36rem;
                line-height: 0.5rem;
                padding: 0 0.1rem;
                color: $main_theme_color;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                .fkd_icon {
                  display: inline-block;
                  width: 0.24rem;
                  height: 0.24rem;
                  background-image: url("/static/images/createCases/pc_doc_shunfeng.png");
                  background-size: 100% 100%;
                  border-radius: 100%;
                  margin-right: 0.04rem;
                }
              }
            }
            .s_c__up {
              display: flex;
              align-items: center;
              font-weight: normal;
              margin-bottom: 0.16rem;
              font-size: 14px;
              color: $main_theme_color_333;
              margin-left: 18px;
              .upload-file {
                margin: 0 0.2rem;
              }
              .up_load {
                display: inline-block;
                height: 0.4rem;
                line-height: 0.4rem;
                margin: 0 0.16rem;
                border-radius: 0.03rem;
                outline: none;
                padding: 0 0.2rem;
                position: relative;
              }
              .up_icon {
                display: inline-block;
                width: 0.24rem;
                height: 0.24rem;
                background-image: url("/static/images/createCases/web_doc_upload.png");
                background-size: 100% 100%;
                cursor: pointer;
              }
              .up_status {
                color: #5cb6ff;
              }
              .up_file {
                display: flex;
                align-items: center;
                margin-left: 0.2rem;
                .up_status {
                  color: #5cb6ff;
                  margin-left: 0.1rem;
                }
              }
              .other_file {
                margin-bottom: 0.1rem;
              }
            }
          }
        }
        .s_c__r_box {
          display: flex;
          align-items: center;
          margin-bottom: 0.2rem;
          .s_c__sp {
            min-width: 1.1rem;
            height: 0.4rem;
            line-height: 0.4rem;
            text-align: center;
            padding: 0 0.14rem;
            border: 0.01rem solid #c8c8c8;
            border-radius: 0.04rem;
            margin-left: 0.2rem;
            cursor: pointer;
            box-sizing: border-box;
            font-size: 14px;
            color: $main_theme_color_333;
          }
          .s_c__sp_active {
            background-color: $main_theme_color;
            color: $main_theme_color_333;
            border: 0.01rem solid $main_theme_color;
          }
        }
      }
    }
  }
  .c_foo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.vertical_line {
  width: 4px;
  height: 26px;
}
</style>
